import React from "react";
import Popover from "@cx/ui/Popover";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SelectInput from "@cx/ui/SelectInput";
import PriceInput from "@cx/ui/PriceInput";
import TextInput from "@cx/ui/TextInput";
import PropTypes from "prop-types";
import { formatPrice } from "../../../../../../commonUtil/utils/formatter";
import {
  isDifferentValue,
  sanitize,
  toEmptyStringIfUndefined
} from "../../../../../../commonUtil/utils/string";
import { doesEmpty } from "../../../../../../commonUtil/utils/object";
import { toast } from "@cx/ui/Toast";
import CheckBoxList from "@cx/ui/CheckBoxList";
import { makeSecureRestApi } from "../../../../../../api/xmmAxios";
import { OperationContext } from "../../../operation-context";
import {
  TaxTypeSettingOptions,
  LaborCostMethodOptions,
  getCatalogFromMakeVariantMap
} from "./../../../../../../constants/ModuleConstants";
import StatusBox from "../../../../../../commonUtil/components/templates/StatusBox";
import { appplyDefaultDmsFieldValues } from "../../../../../reusable/operation";
/* eslint-disable no-console */

class BaseSettings extends React.Component {
  static contextType = OperationContext;
  static propTypes = {
    onChange: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.onBlurPrice = this.onBlurPrice.bind(this);
    this.onBlurCaption = this.onBlurCaption.bind(this);
    this.onChangePrice = this.onChangePrice.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeSelection = this.onChangeSelection.bind(this);
    this.onChangeCheckBoxList = this.onChangeCheckBoxList.bind(this);

    const {
      appContext,
      loadOperation,
      localeStrings,
      makeRateCodesMap
    } = context;
    const { enableDMSPlusContent } = appContext;
    const laborRateOptions = makeRateCodesMap[loadOperation.make];
    const price = formatPrice(loadOperation.price);
    const serviceFees = formatPrice(loadOperation.serviceFees);
    const laborCostOverride = formatPrice(loadOperation.laborCostOverride);
    const hazardMaterialCharge = formatPrice(
      loadOperation.hazardMaterialCharge
    );
    const defaultTaxCode = loadOperation.defaultTaxCode || "SA090700";
    const laborCostMethod = loadOperation.laborCostMethod || "A";
    this.initializeLocaleValues(localeStrings);

    const isDMS = enableDMSPlusContent;

    const { excludeDiscount, applyShopSupplies } = loadOperation;
    const selectedCheckboxes = [];
    if (excludeDiscount) {
      selectedCheckboxes.push("excludeDiscount");
    }
    if (!!applyShopSupplies || applyShopSupplies === null) {
      selectedCheckboxes.push("applyShopSupplies");
    }
    this.state = {
      isDMS,
      dirty: false,
      payCode: "",
      priceCaption: loadOperation.priceCaption,
      price,
      defaultTaxCode,
      hazardMaterialCharge,
      serviceFees,
      laborCostOverride,
      laborCostMethod,
      defaultPayType: "",
      selectedCheckboxes,
      dealerLaborRateCodeId: toEmptyStringIfUndefined(
        loadOperation.dealerLaborRateCodeId
      ),
      laborRateCodeDesc: loadOperation.laborRateCodeDesc,
      laborRateOptions,
      errors: {
        price: "",
        priceCaption: "",
        dealerLaborRateCodeId: ""
      },
      dinamycDefaultPayTypeOptyions: []
    };
    console.log("Base Settings record", loadOperation, laborRateOptions);
  }

  componentDidMount() {
    // Add logic here if needed
    if (this.state.isDMS) {
      const { dealerCode, loadOperation } = this.context;
      const { isDMS } = this.state;
      const { make } = loadOperation;
      const restUrl = `ops/proxyapi/oeproxy/rest/internal/xmmdealer/getDealerPayTypesByDealerType/${dealerCode}`;
      makeSecureRestApi(
        {
          url: restUrl,
          method: "get",
        },
        data => {
          let filter = [];
          if (data &&data.data && data.data.length) {
            filter = data.data
              .filter(item =>
                make === "ANY" || make === "ALL MAKES"
                  ? item.make === "ALL MAKES"
                  : item.make === make || item.make === "ALL MAKES"
              )
              .map(item => {
                return {
                  value: item.description,
                  label: item.description
                };
              });
            this.setState({
              dinamycDefaultPayTypeOptyions: filter
            });
          }
          const { loadOperation } = this.context;
          this.setState({
            defaultPayType:
              filter.length && loadOperation.defaultPayType
                ? filter.find(
                    item => item.value === loadOperation.defaultPayType
                  )
                  ? loadOperation.defaultPayType
                  : isDMS
                  ? "C"
                  : ""
                : isDMS && filter.find(item => item.value === "C")
                ? "C"
                : ""
          });

          if (!filter.length) {
            const msg = this.emptyPayType;
            this.updateStatusBox(msg, "error", false, true);
            toast.error(msg);
          }
        },
        error => {
          const msg = error["message"]
            ? error.message
            : "There was an error while fetching data for this dealer.  Please try again later.";
          this.updateStatusBox(msg, "error", false, true);
        }
      );
    }
  }

  initializeLocaleValues(localeStrings) {
    this.baseSettingsLabel =
      localeStrings["xmm.portal.operations.tab.pricing_opcodes.base_settings"];
    this.pricingAddOnsLabel =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.pricing_add_ons"
      ];
    this.defaultTotalPriceLabel =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.default_total_price"
      ];
    this.defaultLaborRateLabel =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.default_labor_rate"
      ];
    this.defaultPayTypeLabel =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.default_pay_type"
      ];
    this.priceCaptionLabel =
      localeStrings["xmm.portal.operations.tab.pricing_opcodes.price_caption"];
    this.excludeDiscount =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.exclude_discount"
      ];
    this.applyShopSupplies =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.apply_shop_supplies"
      ];
    this.taxType =
      localeStrings["xmm.portal.operations.tab.pricing_opcodes.tax_type"];
    this.hazardMaterialCharge =
      localeStrings["xmm.portal.operations.tab.pricing_hazard_material_charge"];
    this.serviceFees =
      localeStrings["xmm.portal.operations.tab.pricing_opcodes.service_fees"];
    this.LaborCostOverride =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.labor_cost_method"
      ];
    this.laborCostMethod =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.labor_cost_method"
      ];
    this.emptyPayType =
      localeStrings[
        "xmm.portal.operations.tab.pricing_opcodes.empty_pay_types"
      ];
  }

  // used for pay code
  onChange = cxEvent => {
    const { name, value } = cxEvent.target;
    this.setState({ [name]: value });
  };

  onChangeLaborRate = cxEvent => {
    const { name, value } = cxEvent.target;
    const { dealerCode } = this.context;
    if (isDifferentValue(this.state[name], value)) {
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            dirty: true, // set dirty flag to avoid server call when there is no change
            [name]: value,
            errors
          };
        },
        () => {
          // callback here
          const payload = {
            dealerLaborRateCodeId: doesEmpty(value) ? "" : parseInt(value, 10),
            dealerCode
          };
          this.saveBaseSettings(payload, name);
        }
      );
    }
  };
  onChangeSelection = cxEvent => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(this.state[name], value)) {
      this.setState(
        prevState => {
          const { errors } = prevState;
          return {
            dirty: true, // set dirty flag to avoid server calls when there is no change
            [name]: value,
            errors
          };
        },
        () => {
          // callback here
          const { dealerCode } = this.context;
          const payload = {
            [name]: value,
            dealerCode
          };
          if (name === "laborCostMethod" && value != "Fixed") {
            this.saveBaseSettings(
              { laborCostOverride: "", dealerCode },
              "laborCostOverride"
            );
            this.setState({ laborCostOverride: "" });
          }
          this.saveBaseSettings(payload, name);
        }
      );
    }
  };
  onChangeCheckBoxList = cxEvent => {
    //
    const { name, value } = cxEvent.target;
    this.setState({ selectedCheckboxes: value }, () => {
      const { dealerCode } = this.context;
      const payload = {
        applyShopSupplies: value.includes("applyShopSupplies") ? 1 : 0,
        excludeDiscount: value.includes("excludeDiscount") ? 1 : 0,
        dealerCode
      };
      this.saveBaseSettings(payload, name);
    });
  };
  onChangePrice = cxEvent => {
    const { name, value } = cxEvent.target;
    if (isDifferentValue(this.state[name], value)) {
      this.setState(prevState => {
        const { errors } = prevState;
        return {
          dirty: true, // set dirty flag to avoid server calls when there is no change
          [name]: name === "priceCaption" ? sanitize(value) : value,
          errors
        };
      });
    }
  };

  // price = $0 to $9999.99;
  onBlurPrice = cxEvent => {
    const { name, value } = cxEvent.target;
    const valid = this.validate(name);
    const { dirty } = this.state;
    if (dirty && valid) {
      const newVal = formatPrice(value);
      const payload = {
        [name]: newVal,
        dealerCode: this.context.dealerCode
      };
      this.saveBaseSettings(payload, name);
      // this.setState({ : newVal });
    }
  };

  // NOTE: CX BUG - event.target.name missing in NumberInput, TextInput
  onBlurCaption = event => {
    const { value } = event.target;
    const fieldName = "priceCaption";
    const valid = true;
    const { dirty } = this.state;
    if (dirty && valid) {
      const payload = {
        priceCaption: doesEmpty(value) ? "" : value,
        dealerCode: this.context.dealerCode
      };
      if (!value || value.length <= 64) {
        this.saveBaseSettings(payload, fieldName);
      }
    }
  };

  setErrors(name, errorMsg) {
    const { errors } = this.state;
    errors[name] = !errorMsg ? "" : errorMsg;
    this.setState({
      errors
    });
  }
  /* common validator called upon onblur() of each field */
  validate(fieldName) {
    const { errors } = this.state;
    let valid = false;
    const { price, dealerLaborRateCodeId, laborCostOverride } = this.state;

    if (fieldName === "price") {
      if (doesEmpty(price)) {
        errors["price"] = "";
        valid = true;
      } else if ((parseInt(price, 10) || 0) < 0) {
        errors["price"] = "Minimum value for this field is 0";
      } else if (price && (parseFloat(price) || 0) > 9999.99) {
        errors["price"] = "Maximum value for this field is 9999.99";
      } else {
        errors["price"] = "";
        valid = true;
      }
    } else if (fieldName === "dealerLaborRateCodeId") {
      if (doesEmpty(dealerLaborRateCodeId)) {
        valid = true;
        errors["dealerLaborRateCodeId"] = "";
      }
    } else if (fieldName === "laborCostOverride") {
      if (!laborCostOverride || parseFloat(laborCostOverride) <= 999.99) {
        valid = true;
        errors["laborCostOverride"] = "";
      }
    }

    if (valid) {
      // validate fields any time
      valid = !this.hasErrorStrings(errors);
    }
    this.setState({ valid, errors });
    return valid;
  }

  /* This func) checks for error object has strings with {null,"", undefined} in given object
  and returns count for error strings. */
  hasErrorStrings(errors) {
    if (Object.keys(errors).length) {
      const array1 = Object.values(errors);
      const iterator = array1.values();
      let errCount = 0;
      for (const value of iterator) {
        if (value === "" || value === null || value.length === 0) {
          // In case of valid error string
        } else if (value && typeof value === "string") {
          errCount++;
        }
      }
      return errCount === 0 ? false : true;
    } else {
      return false;
    }
  }
  // appplyDefaultDmsFieldValues(operation, payload, field) {
  //   DefaultDmsPlusFields.forEach(name => {
  //     if (field !== name && toEmptyStringIfUndefined(operation[name] === "")) {
  //       switch (name) {
  //         case "applyShopSupplies":
  //           payload.applyShopSupplies = 1;
  //           break;
  //         case "defaultPayType":
  //           payload.defaultPayType = "Customer Pay";
  //           break;
  //         case "defaultTaxCode":
  //           payload.defaultTaxCode = "SA090700";
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //   });
  // }
  saveBaseSettings(payload, field) {
    const { operation } = this.props;
    const { globalRepairOpFlag } = operation;
    const { isDMS } = this.state;
    if (isDMS) {
      appplyDefaultDmsFieldValues(operation, payload, field);
    }
    if (globalRepairOpFlag) {
      this.saveGlobalRepairBaseSettigs(payload, field);
    } else {
      this.saveMaintenanceBaseSettings(payload, field);
    }
  }

  saveGlobalRepairBaseSettigs(payload, field) {
    this.updateStatusBox("Saving", "pending");
    const { dealerCode, user } = this.context.appContext;
    const { loadOperation } = this.context;
    const { serviceId } = loadOperation;
    const objGlobalRepair = {
      modUser: user.userName,
      modTime: "now"
    };
    if (field === "pricingCheckBoxList") {
      objGlobalRepair["applyShopSupplies"] = !payload["applyShopSupplies"]
        ? 0
        : 1;
      objGlobalRepair["excludeDiscount"] = !payload["excludeDiscount"] ? 0 : 1;
    } else {
      objGlobalRepair[field] = !payload[field] ? null : payload[field];
    }

    const restEndPoint = `/ops/operations/overrides/dealerCode/${dealerCode}/operation/${serviceId.substring(
      1
    )}`;
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: objGlobalRepair
      },
      service => {
        if (service) {
          if (field === "pricingCheckBoxList") {
            loadOperation["applyShopSupplies"] = payload["applyShopSupplies"];
            loadOperation["excludeDiscount"] = payload["excludeDiscount"];
          } else {
            loadOperation[field] = payload[field];
          }
          this.context.updateGridAfterSave("edit", loadOperation);
          this.updateStatusBox("Saved", "success", true);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg);
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }

  /* Handler to save single field edits */
  saveMaintenanceBaseSettings(payload, field) {
    this.updateStatusBox("Saving", "pending");
    const { loadOperation } = this.context;
    const { make, serviceId } = loadOperation;
    const restEndPoint =
      "/ops/proxyapi/ddsproxy/rest/table/dealerOperation/" +
      serviceId +
      "?_method=put";
    const { makeVariantMap } = this.context.appContext;
    const { baseLocale } = getCatalogFromMakeVariantMap(makeVariantMap, make);
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Variant-Base-Locale": baseLocale
    };
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data: payload,
        params: {},
        headers
      },
      data => {
        if (data && data.success) {
          const {
            internalName,
            name,
            description,
            inspectionItem,
            serviceTag
          } = loadOperation;
          const dealerOperation = Object.assign(data.dealerOperation, {
            internalName,
            name,
            description,
            inspectionItem,
            serviceTag
          });
          this.setState({
            dirty: false,
            valid: true
          });
          // update context {loadOperation}
          if (make !== "ANY") {
            if (field === "dealerLaborRateCodeId") {
              this.getDealerLaborRateCode(loadOperation);
            } else {
              Object.assign(loadOperation, dealerOperation);
              if (field === "price") {
                this.props.updatePriceStatus(loadOperation);
              } else {
                this.context.updateGridAfterSave("edit", loadOperation);
              }
            }
          } else {
            loadOperation[field] = payload[field];
            if (field === "price") {
              this.props.updatePriceStatus(loadOperation);
            } else {
              this.context.updateGridAfterSave("edit", loadOperation);
            }
          }
          this.updateStatusBox("Saved", "success", true);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error saving your changes.";
        toast.error(msg);
        this.updateStatusBox(msg, "error", false, true);
      }
    );
  }

  getDealerLaborRateCode = loadOperation => {
    const { dealerCode } = this.context.appContext;
    const { serviceId } = loadOperation;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/findDealerOperationsList",
        method: "get",
        data: {},
        params: { dealerCode, serviceId }
      },
      response => {
        const servicelist = response.data;
        if (servicelist && servicelist.length !== 0) {
          const { dealerLaborRateCodeId } = servicelist[0];
          loadOperation.dealerLaborRateCodeId = dealerLaborRateCodeId;
          this.setState({ dealerLaborRateCodeId }, () => {
            this.context.updateGridAfterSave("edit", loadOperation);
          });
        }
      },
      error => {
        toast.error(error.message);
      }
    );
  };
  updateStatusBox(msg, type, autoClose, errorInTooltip) {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose,
        statusType: type,
        errorInTooltip
      });
    });
  }
  renderStatusBox() {
    return this.state.statusMsg ? (
      <div className="xmm-status-override">
        <StatusBox
          htmlId="statusBox"
          type={this.state.statusType}
          autoClose={this.state.autoClose}
          linkHtml={null}
          message={this.state.statusMsg}
          autoCloseTime={1500}
          errorInTooltip={this.state.errorInTooltip}
        />
      </div>
    ) : (
      ""
    );
  }

  render() {
    const { errors, isDMS } = this.state;
    const { loadOperation, appContext } = this.context;
    const { make } = loadOperation;
    const { makeVariantMap, locale, localeStrings } = appContext;
    const { pricingMethod } = getCatalogFromMakeVariantMap(
      makeVariantMap,
      make
    );
    const clsHide = pricingMethod === 2 || locale !== "en_US" ? "hidden" : "";
    const clsHideDmsPlus = isDMS && !clsHide ? "" : "hidden";
    const clsHideRow = pricingMethod === 0 ? "hidden" : "xmm-fixed-row";

    const basePopover = (
      <div>
        {make === "ANY" || pricingMethod !== 1 ? (
          ""
        ) : (
          <>
            <b>{this.defaultLaborRateLabel}:</b>
            <p className="xmm-popover-text">
              {localeStrings["xmm.portal.operations.pricing.labor_rate_tip"]}
            </p>
          </>
        )}
        <b className={clsHideDmsPlus}>{this.defaultPayTypeLabel}:</b>
        <p className={`${clsHideDmsPlus} xmm-popover-text`}>
          {localeStrings["xmm.portal.operations.pricing.default_pay_type_tip"]}
        </p>
        <b>{this.defaultTotalPriceLabel}:</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.pricing.total_price_tip"]}
        </p>
        <b>{this.priceCaptionLabel}:</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.pricing.price_caption_tip"]}
        </p>
      </div>
    );
    const popoverPayType = (
      <div>
        <b className={clsHideDmsPlus}>{this.defaultPayTypeLabel}:</b>
        <p className={`${clsHideDmsPlus} xmm-popover-text`}>
          {localeStrings["xmm.portal.operations.pricing.default_pay_type_tip"]}
        </p>
      </div>
    );
    const popoverTotalPrice = (
      <div>
        <b>{this.defaultTotalPriceLabel}:</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.pricing.total_price_tip"]}
        </p>
      </div>
    );
    const popoverPriceCaption = (
      <div>
        <b>{this.priceCaptionLabel}:</b>
        <p className="xmm-popover-text">
          {localeStrings["xmm.portal.operations.pricing.price_caption_tip"]}
        </p>
      </div>
    );
    const pricingAddOnsPopover = (
      <div>
        <b>{this.excludeDiscount}:</b>
        <p className="xmm-popover-text">
          {
            localeStrings[
              "xmm.portal.operations.tab.pricing_opcodes.exclude_discount_tip"
            ]
          }
        </p>
        <b>{this.taxType}:</b>
        <p className="xmm-popover-text">
          {
            localeStrings[
              "xmm.portal.operations.tab.pricing_opcodes.tax_type_tip"
            ]
          }
        </p>
        <b>{this.LaborCostOverride}:</b>
        <p className="xmm-popover-text">
          {
            localeStrings[
              "xmm.portal.operations.tab.pricing_opcodes.labor_cots_override_tip"
            ]
          }
        </p>
      </div>
    );
    const statusBox = this.renderStatusBox();
    return (
      <form autoComplete="off">
        <div className="xmm-header-info">
          <h4>{this.baseSettingsLabel}</h4>
          {!isDMS && (
            <Popover
              htmlId="popoverBaseSettings"
              popoverContent={basePopover}
              trigger={["click", "outsideClick"]}
            >
              <IconInfoOutline
                htmlId="baseSettingsInfo"
                className="hand-cursor"
              />
            </Popover>
          )}
          <span className="fixed-alert-msg">{statusBox}</span>
        </div>
        <Grid>
          <Row className={clsHideRow}>
            <Col xs={3} md={3} hidden={isDMS || pricingMethod === 2}>
              <SelectInput
                htmlId="dealerLaborRateCodeId"
                label={this.defaultLaborRateLabel}
                name="dealerLaborRateCodeId"
                options={this.state.laborRateOptions || []}
                value={this.state.dealerLaborRateCodeId || ""}
                onChange={this.onChangeLaborRate}
                placeholder=""
                displayDeselectOption={!this.state.dealerLaborRateCodeId}
                displayPlaceholder={true}
                error={errors.dealerLaborRateCodeId}
              />
            </Col>
            <Col xs={3} md={3} className={clsHideDmsPlus}>
              <div>
                <span className="xmm-select-menu-label">
                  {this.defaultPayTypeLabel}
                </span>
                {isDMS && (
                  <Popover
                    htmlId="popoverPayType"
                    popoverContent={popoverPayType}
                    trigger={["click", "outsideClick"]}
                  >
                    <IconInfoOutline
                      htmlId="PayTypeInfo"
                      className="hand-cursor"
                    />
                  </Popover>
                )}
              </div>
              <SelectInput
                htmlId="defaultPayTypeId"
                displayLabel={false}
                name="defaultPayType"
                options={this.state.dinamycDefaultPayTypeOptyions || []}
                value={this.state.defaultPayType || ""}
                onChange={this.onChangeSelection}
                placeholder=""
                displayDeselectOption={false}
                displayPlaceholder={true}
                error={errors.defaultPayType}
                className={clsHideDmsPlus}
                autoComplete={false}
              />
            </Col>
            <Col xs={3} md={3}>
              <div>
                <span className="xmm-select-menu-label">
                  {this.defaultTotalPriceLabel}
                </span>
                {isDMS && (
                  <Popover
                    htmlId="popoverPrice"
                    popoverContent={popoverTotalPrice}
                    trigger={["click", "outsideClick"]}
                  >
                    <IconInfoOutline
                      htmlId="PriceInfo"
                      className="hand-cursor"
                    />
                  </Popover>
                )}
              </div>
              <PriceInput
                htmlId="price"
                displayLabel={false}
                name="price"
                autoComplete="off"
                onChange={this.onChangePrice}
                onBlur={this.onBlurPrice}
                maxLength={7}
                error={errors.price}
                value={this.state.price}
              />
            </Col>
            {/* <SelectInput
              htmlId="payCode"
              label="Pay code"
              name="payCode"
              displayPlaceholder={false}
              displayDeselectOption={false}
              className="hide"
              onChange={this.onChange}
              options={[
                { value: "1", label: "Item 1" },
                { value: "2", label: "Item 2" },
                { value: "3", label: "Item 3" },
                { value: "4", label: "Item 4" }
              ]}
              value={this.state.payCode || ""}
            /> */}
            <Col xs={3} md={3}>
              <div>
                <span className="xmm-select-menu-label">
                  {this.priceCaptionLabel}
                </span>
                {isDMS && (
                  <Popover
                    htmlId="popoverPriceCaption"
                    popoverContent={popoverPriceCaption}
                    trigger={["click", "outsideClick"]}
                  >
                    <IconInfoOutline
                      htmlId="PriceCaptionInfo"
                      className="hand-cursor"
                    />
                  </Popover>
                )}
              </div>
              <TextInput
                htmlId="priceCaption"
                displayLabel={false}
                name="priceCaption"
                maxLength={64}
                autoComplete="off"
                disabled={this.props.operation.globalRepairOpFlag}
                onChange={this.onChangePrice}
                onBlur={this.onBlurCaption}
                error={errors.priceCaption}
                value={this.state.priceCaption || ""}
              />
            </Col>
          </Row>
        </Grid>
        <hr className={clsHideDmsPlus} />
        <div className={`xmm-header-info ${clsHideDmsPlus}`}>
          <h4>{this.pricingAddOnsLabel}</h4>
          <Popover
            htmlId="popoverPricingAddOns"
            popoverContent={pricingAddOnsPopover}
            trigger={["click", "outsideClick"]}
          >
            <IconInfoOutline
              htmlId="baseSettingsInfo"
              className="hand-cursor"
            />
          </Popover>
        </div>
        <Grid className={clsHideDmsPlus}>
          <Row className={`xmm-prixing-ons  ${clsHideRow}`}>
            <Col
              xs={2}
              md={2}
              className="no-padding-r exclude-discount-container"
            >
              <div className="xmm-checkboxlist exclude-discount-container">
                <CheckBoxList
                  htmlId="pricingCheckBoxList"
                  name="pricingCheckBoxList"
                  label=""
                  displayLabel={false}
                  values={this.state.selectedCheckboxes || []}
                  options={[
                    { value: "excludeDiscount", label: this.excludeDiscount }
                    // {
                    //   value: "applyShopSupplies",
                    //   label: this.applyShopSupplies
                    // }
                  ]}
                  onChange={this.onChangeCheckBoxList}
                />
              </div>
            </Col>
            <Col xs={3} md={3} className={`no-padding-lr ${clsHide}`}>
              <SelectInput
                htmlId="taxTypeSelectorId"
                label={this.taxType}
                name="defaultTaxCode"
                options={TaxTypeSettingOptions}
                value={this.state.defaultTaxCode}
                onChange={this.onChangeSelection}
                placeholder=""
                displayDeselectOption={false}
                displayPlaceholder={true}
                error={errors.defaultTaxCode}
                className={`tax-selector-container ${clsHide}`}
              />
            </Col>
            <Col xs={3} md={3} className="hidden no-padding-lr">
              <PriceInput
                htmlId="hazardaterialChargeId"
                label={this.hazardMaterialCharge}
                name="hazardMaterialCharge"
                autoComplete="off"
                className="hidden"
                disabled
                onChange={this.onChangePrice}
                onBlur={this.onBlurPrice}
                maxLength={7}
                error={errors.hazardMaterialCharge}
                value={this.state.hazardMaterialCharge}
              />
            </Col>
            <Col xs={3} md={3} className="hidden no-padding-lr">
              <PriceInput
                htmlId="serviceFeesId"
                label={this.serviceFees}
                name="serviceFees"
                maxLength={64}
                autoComplete="off"
                className="hidden"
                disabled
                onChange={this.onChangePrice}
                onBlur={this.onBlurPrice}
                error={errors.serviceFees}
                value={this.state.serviceFees}
              />
            </Col>
            <Col xs={3} md={3} className="no-padding-lr">
              <div className="xmm-field-group">
                <div className="control-label">{this.laborCostMethod}</div>
                <div className="xmm-field-section">
                  <SelectInput
                    htmlId="laborCostMethodSelectorId"
                    displayLabel={false}
                    name="laborCostMethod"
                    options={LaborCostMethodOptions}
                    value={this.state.laborCostMethod}
                    onChange={this.onChangeSelection}
                    placeholder=""
                    displayDeselectOption={false}
                    displayPlaceholder={true}
                    error={errors.laborCostMethod}
                  />
                  <PriceInput
                    htmlId="laborCostOverrideId"
                    label={this.LaborCostOverride}
                    name="laborCostOverride"
                    autoComplete="off"
                    displayLabel={false}
                    onChange={this.onChangePrice}
                    onBlur={this.onBlurPrice}
                    disabled={this.state.laborCostMethod != "Fixed"}
                    maxLength={6}
                    maxValue={999.99}
                    error={errors.laborCostOverride}
                    value={
                      this.state.laborCostMethod === "Fixed"
                        ? this.state.laborCostOverride
                        : ""
                    }
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </form>
    );
  }
}

export default BaseSettings;

BaseSettings.propTypes = {
  operation: PropTypes.object,
  updatePriceStatus: PropTypes.func
};
